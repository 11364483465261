const tokenAbi = [{
    'constant': true,
    'inputs': [],
    'name': 'name',
    'outputs': [{'name': '', 'type': 'string'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': '_upgradedAddress', 'type': 'address'}],
    'name': 'deprecate',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': '_spender', 'type': 'address'}, {'name': '_value', 'type': 'uint256'}],
    'name': 'approve',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'deprecated',
    'outputs': [{'name': '', 'type': 'bool'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': '_evilUser', 'type': 'address'}],
    'name': 'addBlackList',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'totalSupply',
    'outputs': [{'name': '', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': '_from', 'type': 'address'}, {'name': '_to', 'type': 'address'}, {
        'name': '_value',
        'type': 'uint256'
    }],
    'name': 'transferFrom',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'upgradedAddress',
    'outputs': [{'name': '', 'type': 'address'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [{'name': '', 'type': 'address'}],
    'name': 'balances',
    'outputs': [{'name': '', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'decimals',
    'outputs': [{'name': '', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'maximumFee',
    'outputs': [{'name': '', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': '_totalSupply',
    'outputs': [{'name': '', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [],
    'name': 'unpause',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [{'name': '_maker', 'type': 'address'}],
    'name': 'getBlackListStatus',
    'outputs': [{'name': '', 'type': 'bool'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [{'name': '', 'type': 'address'}, {'name': '', 'type': 'address'}],
    'name': 'allowed',
    'outputs': [{'name': '', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'paused',
    'outputs': [{'name': '', 'type': 'bool'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [{'name': 'who', 'type': 'address'}],
    'name': 'balanceOf',
    'outputs': [{'name': '', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [],
    'name': 'pause',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'getOwner',
    'outputs': [{'name': '', 'type': 'address'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'owner',
    'outputs': [{'name': '', 'type': 'address'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'symbol',
    'outputs': [{'name': '', 'type': 'string'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': '_to', 'type': 'address'}, {'name': '_value', 'type': 'uint256'}],
    'name': 'transfer',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': 'newBasisPoints', 'type': 'uint256'}, {'name': 'newMaxFee', 'type': 'uint256'}],
    'name': 'setParams',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': 'amount', 'type': 'uint256'}],
    'name': 'issue',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': 'amount', 'type': 'uint256'}],
    'name': 'redeem',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [{'name': '_owner', 'type': 'address'}, {'name': '_spender', 'type': 'address'}],
    'name': 'allowance',
    'outputs': [{'name': 'remaining', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'basisPointsRate',
    'outputs': [{'name': '', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [{'name': '', 'type': 'address'}],
    'name': 'isBlackListed',
    'outputs': [{'name': '', 'type': 'bool'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': '_clearedUser', 'type': 'address'}],
    'name': 'removeBlackList',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': true,
    'inputs': [],
    'name': 'MAX_UINT',
    'outputs': [{'name': '', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'view',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': 'newOwner', 'type': 'address'}],
    'name': 'transferOwnership',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'constant': false,
    'inputs': [{'name': '_blackListedUser', 'type': 'address'}],
    'name': 'destroyBlackFunds',
    'outputs': [],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'function'
}, {
    'inputs': [{'name': '_initialSupply', 'type': 'uint256'}, {
        'name': '_name',
        'type': 'string'
    }, {'name': '_symbol', 'type': 'string'}, {'name': '_decimals', 'type': 'uint256'}],
    'payable': false,
    'stateMutability': 'nonpayable',
    'type': 'constructor'
}, {
    'anonymous': false,
    'inputs': [{'indexed': false, 'name': 'amount', 'type': 'uint256'}],
    'name': 'Issue',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{'indexed': false, 'name': 'amount', 'type': 'uint256'}],
    'name': 'Redeem',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{'indexed': false, 'name': 'newAddress', 'type': 'address'}],
    'name': 'Deprecate',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{'indexed': false, 'name': 'feeBasisPoints', 'type': 'uint256'}, {
        'indexed': false,
        'name': 'maxFee',
        'type': 'uint256'
    }],
    'name': 'Params',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{'indexed': false, 'name': '_blackListedUser', 'type': 'address'}, {
        'indexed': false,
        'name': '_balance',
        'type': 'uint256'
    }],
    'name': 'DestroyedBlackFunds',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{'indexed': false, 'name': '_user', 'type': 'address'}],
    'name': 'AddedBlackList',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{'indexed': false, 'name': '_user', 'type': 'address'}],
    'name': 'RemovedBlackList',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{'indexed': true, 'name': 'owner', 'type': 'address'}, {
        'indexed': true,
        'name': 'spender',
        'type': 'address'
    }, {'indexed': false, 'name': 'value', 'type': 'uint256'}],
    'name': 'Approval',
    'type': 'event'
}, {
    'anonymous': false,
    'inputs': [{'indexed': true, 'name': 'from', 'type': 'address'}, {
        'indexed': true,
        'name': 'to',
        'type': 'address'
    }, {'indexed': false, 'name': 'value', 'type': 'uint256'}],
    'name': 'Transfer',
    'type': 'event'
}, {'anonymous': false, 'inputs': [], 'name': 'Pause', 'type': 'event'}, {
    'anonymous': false,
    'inputs': [],
    'name': 'Unpause',
    'type': 'event'
}]
const factoryAbi = [{
    "inputs": [{"internalType": "address", "name": "_core", "type": "address"}],
    "stateMutability": "nonpayable",
    "type": "constructor"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "_core", "type": "address"}],
    "name": "CoreUpdate",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "token0", "type": "address"}, {
        "indexed": true,
        "internalType": "address",
        "name": "token1",
        "type": "address"
    }, {"indexed": false, "internalType": "address", "name": "pair", "type": "address"}, {
        "indexed": false,
        "internalType": "uint256",
        "name": "",
        "type": "uint256"
    }],
    "name": "PairCreated",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": false, "internalType": "address", "name": "account", "type": "address"}],
    "name": "Paused",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": false, "internalType": "address", "name": "account", "type": "address"}],
    "name": "Unpaused",
    "type": "event"
}, {
    "inputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "name": "allPairs",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "allPairsLength",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "core",
    "outputs": [{"internalType": "contract ICore", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "tokenA", "type": "address"}, {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
    }],
    "name": "createPair",
    "outputs": [{"internalType": "address", "name": "pair", "type": "address"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_pair", "type": "address"}],
    "name": "fee",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}, {
        "internalType": "bool",
        "name": "",
        "type": "bool"
    }, {"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "feeScale",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}],
    "name": "feeSpec",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "feeStakeScale",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "feeTo",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "", "type": "address"}, {
        "internalType": "address",
        "name": "",
        "type": "address"
    }],
    "name": "getPair",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "getPairInitCode",
    "outputs": [{"internalType": "bytes32", "name": "", "type": "bytes32"}],
    "stateMutability": "pure",
    "type": "function"
}, {
    "inputs": [],
    "name": "getUSDMAmountGovernance",
    "outputs": [{"internalType": "uint256", "name": "usdmAmount", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "pause", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [],
    "name": "paused",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "core_", "type": "address"}],
    "name": "setCore",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "setFeeNoSpec",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "_feeScale", "type": "uint256"}],
    "name": "setFeeScale",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_pair", "type": "address"}, {
        "internalType": "uint256",
        "name": "_feeScale",
        "type": "uint256"
    }], "name": "setFeeSpec", "outputs": [], "stateMutability": "nonpayable", "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "_feeStakeScale", "type": "uint256"}],
    "name": "setFeeStakeScale",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_feeTo", "type": "address"}],
    "name": "setFeeTo",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {"inputs": [], "name": "unpause", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [],
    "name": "usdm",
    "outputs": [{"internalType": "contract IUSDMToken", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "usdmBalance",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "xms",
    "outputs": [{"internalType": "contract IXMSToken", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "xmsBalance",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}]
const lpAbi = [{
    "inputs": [{"internalType": "address", "name": "_core", "type": "address"}, {
        "internalType": "address",
        "name": "_factory",
        "type": "address"
    }, {"internalType": "address", "name": "_WETH", "type": "address"}, {
        "internalType": "address",
        "name": "_swapMining",
        "type": "address"
    }], "stateMutability": "nonpayable", "type": "constructor"
}, {
    "anonymous": false,
    "inputs": [{"indexed": true, "internalType": "address", "name": "_core", "type": "address"}],
    "name": "CoreUpdate",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": false, "internalType": "address", "name": "account", "type": "address"}],
    "name": "Paused",
    "type": "event"
}, {
    "anonymous": false,
    "inputs": [{"indexed": false, "internalType": "address", "name": "account", "type": "address"}],
    "name": "Unpaused",
    "type": "event"
}, {
    "inputs": [],
    "name": "WETH",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "tokenA", "type": "address"}, {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
    }, {"internalType": "uint256", "name": "amountADesired", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountBDesired",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "amountAMin", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountBMin",
        "type": "uint256"
    }, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
    }],
    "name": "addLiquidity",
    "outputs": [{"internalType": "uint256", "name": "amountA", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "liquidity", "type": "uint256"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
        "internalType": "uint256",
        "name": "amountTokenDesired",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "amountTokenMin", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
    }, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
    }],
    "name": "addLiquidityETH",
    "outputs": [{"internalType": "uint256", "name": "amountToken", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "liquidity", "type": "uint256"}],
    "stateMutability": "payable",
    "type": "function"
}, {
    "inputs": [],
    "name": "core",
    "outputs": [{"internalType": "contract ICore", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "factory",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "reserveIn",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "reserveOut", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "feeScale",
        "type": "uint256"
    }],
    "name": "getAmountIn",
    "outputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "reserveIn",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "reserveOut", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "feeScale",
        "type": "uint256"
    }],
    "name": "getAmountOut",
    "outputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
    }],
    "name": "getAmountsIn",
    "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
    }],
    "name": "getAmountsOut",
    "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "getUSDMAmountGovernance",
    "outputs": [{"internalType": "uint256", "name": "usdmAmount", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {"inputs": [], "name": "pause", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [],
    "name": "paused",
    "outputs": [{"internalType": "bool", "name": "", "type": "bool"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountA", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "reserveA",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "reserveB", "type": "uint256"}],
    "name": "quote",
    "outputs": [{"internalType": "uint256", "name": "amountB", "type": "uint256"}],
    "stateMutability": "pure",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "tokenA", "type": "address"}, {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
    }, {"internalType": "uint256", "name": "liquidity", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountAMin",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "amountBMin", "type": "uint256"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
    "name": "removeLiquidity",
    "outputs": [{"internalType": "uint256", "name": "amountA", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "amountTokenMin", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
    }, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
    }],
    "name": "removeLiquidityETH",
    "outputs": [{"internalType": "uint256", "name": "amountToken", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "amountTokenMin", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
    }, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
    }],
    "name": "removeLiquidityETHSupportingFeeOnTransferTokens",
    "outputs": [{"internalType": "uint256", "name": "amountETH", "type": "uint256"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "amountTokenMin", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
    }, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
    }, {"internalType": "bool", "name": "approveMax", "type": "bool"}, {
        "internalType": "uint8",
        "name": "v",
        "type": "uint8"
    }, {"internalType": "bytes32", "name": "r", "type": "bytes32"}, {
        "internalType": "bytes32",
        "name": "s",
        "type": "bytes32"
    }],
    "name": "removeLiquidityETHWithPermit",
    "outputs": [{"internalType": "uint256", "name": "amountToken", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountETH",
        "type": "uint256"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "token", "type": "address"}, {
        "internalType": "uint256",
        "name": "liquidity",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "amountTokenMin", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountETHMin",
        "type": "uint256"
    }, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
    }, {"internalType": "bool", "name": "approveMax", "type": "bool"}, {
        "internalType": "uint8",
        "name": "v",
        "type": "uint8"
    }, {"internalType": "bytes32", "name": "r", "type": "bytes32"}, {
        "internalType": "bytes32",
        "name": "s",
        "type": "bytes32"
    }],
    "name": "removeLiquidityETHWithPermitSupportingFeeOnTransferTokens",
    "outputs": [{"internalType": "uint256", "name": "amountETH", "type": "uint256"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "tokenA", "type": "address"}, {
        "internalType": "address",
        "name": "tokenB",
        "type": "address"
    }, {"internalType": "uint256", "name": "liquidity", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountAMin",
        "type": "uint256"
    }, {"internalType": "uint256", "name": "amountBMin", "type": "uint256"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}, {
        "internalType": "bool",
        "name": "approveMax",
        "type": "bool"
    }, {"internalType": "uint8", "name": "v", "type": "uint8"}, {
        "internalType": "bytes32",
        "name": "r",
        "type": "bytes32"
    }, {"internalType": "bytes32", "name": "s", "type": "bytes32"}],
    "name": "removeLiquidityWithPermit",
    "outputs": [{"internalType": "uint256", "name": "amountA", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountB",
        "type": "uint256"
    }],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "core_", "type": "address"}],
    "name": "setCore",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "address", "name": "_swapMining", "type": "address"}],
    "name": "setSwapMining",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
    }, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
    }],
    "name": "swapETHForExactTokens",
    "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
    "stateMutability": "payable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountOutMin", "type": "uint256"}, {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
    }, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
    }],
    "name": "swapExactETHForTokens",
    "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
    "stateMutability": "payable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountOutMin", "type": "uint256"}, {
        "internalType": "address[]",
        "name": "path",
        "type": "address[]"
    }, {"internalType": "address", "name": "to", "type": "address"}, {
        "internalType": "uint256",
        "name": "deadline",
        "type": "uint256"
    }],
    "name": "swapExactETHForTokensSupportingFeeOnTransferTokens",
    "outputs": [],
    "stateMutability": "payable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
    }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
    "name": "swapExactTokensForETH",
    "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
    }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
    "name": "swapExactTokensForETHSupportingFeeOnTransferTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
    }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
    "name": "swapExactTokensForTokens",
    "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountIn", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountOutMin",
        "type": "uint256"
    }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
    "name": "swapExactTokensForTokensSupportingFeeOnTransferTokens",
    "outputs": [],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [],
    "name": "swapMining",
    "outputs": [{"internalType": "address", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountInMax",
        "type": "uint256"
    }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
    "name": "swapTokensForExactETH",
    "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {
    "inputs": [{"internalType": "uint256", "name": "amountOut", "type": "uint256"}, {
        "internalType": "uint256",
        "name": "amountInMax",
        "type": "uint256"
    }, {"internalType": "address[]", "name": "path", "type": "address[]"}, {
        "internalType": "address",
        "name": "to",
        "type": "address"
    }, {"internalType": "uint256", "name": "deadline", "type": "uint256"}],
    "name": "swapTokensForExactTokens",
    "outputs": [{"internalType": "uint256[]", "name": "amounts", "type": "uint256[]"}],
    "stateMutability": "nonpayable",
    "type": "function"
}, {"inputs": [], "name": "unpause", "outputs": [], "stateMutability": "nonpayable", "type": "function"}, {
    "inputs": [],
    "name": "usdm",
    "outputs": [{"internalType": "contract IUSDMToken", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "usdmBalance",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "xms",
    "outputs": [{"internalType": "contract IXMSToken", "name": "", "type": "address"}],
    "stateMutability": "view",
    "type": "function"
}, {
    "inputs": [],
    "name": "xmsBalance",
    "outputs": [{"internalType": "uint256", "name": "", "type": "uint256"}],
    "stateMutability": "view",
    "type": "function"
}, {"stateMutability": "payable", "type": "receive"}]
export {tokenAbi, factoryAbi, lpAbi}