<template>
  <div id="app" style="width: 1200px;margin: 0 auto;">
    <el-form ref="form" :model="form" label-width="160px"
             v-loading="loading"
             element-loading-text="正在运行中，请选择停止或等执行结束再操作"
             element-loading-spinner="el-icon-loading"
             element-loading-background="rgba(0, 0, 0, 0.8)"

    >
      <el-form-item label="私钥地址">
        <el-input type="password" v-model="form.pr"></el-input>
      </el-form-item>

      <el-form-item label="公钥地址">
        <el-col :span="8">
          <el-row v-model="form.address">{{ form.address }}</el-row>
        </el-col>

      </el-form-item>
      <el-form-item label="RPC地址">
        <el-input v-model="form.rpc"></el-input>
      </el-form-item>
      <el-form-item label="DEX平台">
        <el-select v-model="form.jiaoyisuo" placeholder="请选择DEX交易所" style="width: 1040px;">
          <el-option label="XMS:https://app.marsecosystem.com,fac合约：0x6f12482d9869303b998c54d91bcd8bccba81f3be"
                     value="0x6f12482d9869303b998c54d91bcd8bccba81f3be"></el-option>
          <!--          0x858E3312ed3A876947EA49d572A7C42DE08af7EE-->
          <el-option label="CAKE:https://pancakeswap.finance" value="cake"></el-option>
          <!--          0x10ed43c718714eb63d5aa57b78b54704e256024e-->
        </el-select>
      </el-form-item>
      <el-form-item label="币种X">
        <el-col :span="9">
          <!--          <el-input v-model="form.x"></el-input>-->
          <el-autocomplete
              style="width: 100%;"
              class="inline-input"
              v-model="form.x"
              :fetch-suggestions="querySearch"
              placeholder="请输入X代币合约或名称"
              @select="reX"
          ></el-autocomplete>
        </el-col>
        <el-col :span="15" style="text-align: left"> 代币名称：{{ form.xName }}余额：{{ form.xBalance }}</el-col>
      </el-form-item>
      <el-form-item label="币种Y">
        <el-col :span="9">
          <el-autocomplete
              style="width: 100%;"
              class="inline-input"
              v-model="form.y"
              :fetch-suggestions="querySearch"
              placeholder="请输入X代币合约或名称"
              @select="reY"
          ></el-autocomplete>
        </el-col>
        <el-col :span="15" style="text-align: left"> 代币名称：{{ form.yName }}余额：{{ form.yBalance }}</el-col>
      </el-form-item>
      <el-form-item label=当前lp合约>
        <el-row>{{ form.lpC }}当前兑换比例（1x兑换y数量）：{{ form.biliNow }}（1y兑换x数量）：{{ form.biliNowFan }}</el-row>
        <el-row>当前lp池子深度：（X:Y）：{{ form.xNum }}：{{ form.yNum }}</el-row>
      </el-form-item>
      <el-form-item label="池子深度">
        <el-col :span="6">
          <el-select v-model="form.lpStartType" placeholder="选择" style="width: 260px;">
            <el-option label="X" value="x"></el-option>
            <el-option label="Y" value="y"></el-option>
          </el-select>
        </el-col>
        <el-col :span="10">
          》》》》》》数量
          <el-input style="width: 200px;" v-model="form.lpNum"></el-input>
          下单
        </el-col>
      </el-form-item>


      <el-form-item label="正向x兑y比例">
        <el-input style="display: inline-block" v-model="form.zhengConfig.bili"></el-input>
      </el-form-item>

      <el-form-item label="正向单次兑换x数量">
        <el-input style="display: inline-block" v-model="form.zhengConfig.num"></el-input>
      </el-form-item>
      <el-form-item label="反向兑换">
        <el-switch v-model="form.fanxiangdaili"></el-switch>
      </el-form-item>
      <el-form-item label="反向兑换类型" v-if="form.fanxiangdaili">
        <el-select v-model="form.fanxiangType" placeholder="请选择反向兑换类型" style="width: 1040px;">
          <el-option label="单独设置反向x兑y比例" value="1"></el-option>
          <el-option label="根据正向x兑y比例设置反向比例" value="2"></el-option>

        </el-select>
      </el-form-item>
      <el-form-item label="反向兑换x兑y比例" v-if="form.fanxiangdaili&&form.fanxiangType==='1'">
        <el-input v-model="form.fanConfig.bili"></el-input>
      </el-form-item>
      <el-form-item label="根据正向x兑y比例设置反向比例" v-if="form.fanxiangdaili&&form.fanxiangType==='2'">
        <el-input v-model="form.fanConfig.reBili"></el-input>
      </el-form-item>
      <el-form-item label="gasPrice">
        <el-input v-model="form.config.gasPrice"></el-input>
      </el-form-item>
      <el-form-item label="gasLimit">
        <el-input v-model="form.config.gasLimit"></el-input>
      </el-form-item>
      <el-form-item label="间隔时间（毫秒）">
        <el-input v-model="form.config.timeLate"></el-input>
      </el-form-item>
      <el-form-item label="滑点上限（%）">
        <el-input v-model="form.config.huadian"></el-input>
      </el-form-item>
      <el-form-item label="只进行1次">
        <el-switch v-model="form.config.oneTime"></el-switch>
      </el-form-item>
    </el-form>
    <el-button type="primary" @click="onSubmit">开始</el-button>
    <el-button @click="onOver">停止</el-button>
    <div>
      如果未选择只进行一次，持续进行正向次数{{ this.logs.length }},
      如果选择了反向兑换。执行反向次数{{ this.logsFan }}
    </div>
  </div>
</template>

<script>
// import HelloWorld from './components/HelloWorld.vue'
import {ethers} from "ethers";
import {tokenAbi, factoryAbi, lpAbi} from './abis'

export default {
  name: 'app',
  components: {
    // HelloWorld
  },
  mounted() {
    let that = this;
    setInterval(async function () {
      await that.reProvider()

    }, 1000);

    setInterval(async function () {
      await that.getAddressPr()

    }, 1000);
    setInterval(async function () {

      await that.getXAndYName();


    }, 1000);
    setInterval(async function () {

      await that.getXAndYBlance();


    }, 1000);
    setInterval(async function () {

      await that.getPLC();

    }, 1000);
    setInterval(async function () {
      await that.getNowBili();
      console.log('z')
    }, 1000);

  },
  data() {
    return {
      restaurants: [
        {"value": "usdt", "address": "0x55d398326f99059ff775485246999027b3197955"},
        {"value": "busd", "address": "0xe9e7cea3dedca5984780bafc599bd69add087d56"},
        {"value": "wbnb", "address": "0xbb4CdB9CBd36B01bD1cBaEBF2De08d9173bc095c"},
        {"value": "usdm", "address": "0xBb0fA2fBE9b37444f5D1dBD22e0e5bdD2afbbE85"},
        {"value": "xms", "address": "0x7859b01bbf675d67da8cd128a50d155cd881b576"},
        {"value": "cake", "address": "0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82"},
      ],
      loading: false,
      provider: false,
      setI: false,
      over: false,
      oneRunning: false,
      logs: [],
      logsFan:0,
      overing:true,
      form: {
        pr: '',
        rpc: 'https://bsc-dataseed4.binance.org/',
        jiaoyisuo: '0x6f12482d9869303b998c54d91bcd8bccba81f3be',
        address: '',

        x: '',
        y: '',
        xName: '',
        xBalance: '',
        yBalance: '',
        yName: '',
        xNum: 0,
        yNum: 0,
        lpC: '',
        lpStartType: 'x',
        lpNum: 10000,
        biliNow: 0,
        biliNowFan: 0,
        fanxiangdaili: false,
        fanxiangType: '1',
        zhengConfig: {
          bili: 1,
          num: '0.0000000001',

        }, fanConfig: {
          bili: 1,
          reBili: 0.9,
          num: 0,

        },
        config: {
          huadian: '0.5',
          gasPrice: '5',
          gasLimit: '200000',
          timeLate: '1400',
          oneTime: false,
        },

        demo: {
          type: 'swap兑换',
          time: (new Date()).toLocaleTimeString(),
        }

      }
    }

  },
  methods: {
    async test() {

    },
    createFilter(queryString) {
      return (restaurant) => {
        return (restaurant.value.toLowerCase().indexOf(queryString.toLowerCase()) === 0);
      };
    },
    async onOver() {
      clearInterval(this.setI)
      this.loading = false
      this.oneRunning = false
      this.logs = []
      this.logsFan = 0
      this.overing=true
    },
    // checkLpShen() {
    //   if (this.form.lpStartType === 'x' && this.form.xNum >= this.form.lpNum) {
    //     return true
    //   }
    //   return this.form.lpStartType === 'y' && this.form.yNum >= this.form.lpNum;
    //
    // },
    async onSubmit() {
      if(!this.form.pr){
        this.$message.error('请填私钥')
        return
      }
      if(!this.form.x){
        this.$message.error('请填X')
        return
      }
      if(!this.form.y){
        this.$message.error('请填Y')
        return
      }
      let that = this
      this.overing=false
      this.loading = true
      if (this.form.config.oneTime !== false) {
        await this.checkApprove()
        this.setI = setInterval(async function () {
          if (that.oneRunning === false) {
            that.oneRunning = true
            if (that.form.biliNow >= that.form.zhengConfig.bili ) {
              try {
                let res = await that.startOne();
                if (res) {
                  that.$message.success('执行成功')
                  clearInterval(that.setI)
                  that.loading = false
                  that.oneRunning = false
                } else {
                  that.$message.error('执行失败')
                  that.loading = false
                  that.oneRunning = false
                }
              } catch (e) {
                console.log(e)
                that.$message.error('执行失败')
                that.loading = false
                that.oneRunning = false
              }

            }
          }

        }, this.form.config.timeLate)

      } else {
        this.setI = setInterval(async function () {
          if (that.oneRunning === false) {
            that.oneRunning = true
            try {
              if (that.form.biliNow >= that.form.zhengConfig.bili ) {
                let res = await that.startOne();
                if (res) {
                  that.oneRunning = false
                } else {
                  that.oneRunning = false
                }
              }
            } catch (e) {
              console.log(e)
              that.oneRunning = false
            }
            try {
              if (that.form.fanxiangdaili) {
                console.log(1);
                if (that.form.fanxiangType === '1') {
                  console.log(2);
                  if (that.form.biliNow < that.form.fanConfig.bili) {
                    console.log(3);
                    if (that.logsFan < that.logs.length) {
                      console.log(4);
                      let res = await that.startOneFan();
                      if (res) {
                        that.form.logsFan++
                        that.oneRunning = false
                      } else {
                        that.oneRunning = false
                      }
                    }

                  }
                }
                if (that.form.fanxiangType === '2') {
                  if (that.form.biliNow < that.form.zhengConfig.bili * that.form.fanConfig.reBili) {
                    if (that.logsFan < that.logs.length ) {
                      let res = await that.startOneFan();
                      if (res) {
                        that.form.logsFan++
                        that.oneRunning = false
                      } else {
                        that.oneRunning = false
                      }
                    }
                  }
                }
              }
            } catch (e) {
              console.log(e)
              that.oneRunning = false
            }
          }
        }, this.form.config.timeLate)
      }

    },
    async getAddressPr() {
      if (this.form.pr) {
        if (this.form.pr[0] !== '0' && this.form.pr[0] !== 'x') {
          this.form.address = ethers.utils.computeAddress('0x' + this.form.pr);
          return
        }
      }
      this.form.address = '';
    },
    querySearch(queryString, cb) {
      let restaurants = this.restaurants;
      let results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    reX(item) {
      this.form.x = item.address
    },
    reY(item) {
      this.form.y = item.address
    },
    async getXAndYBlance() {
      if (this.form.address) {
        if (this.form.x) {

          let contractX = new ethers.Contract(this.form.x, tokenAbi, this.provider)
          let theBalance = await contractX.balanceOf(this.form.address)
          let theDecimals = await contractX.decimals()
          if (theBalance) {
            this.form.xBalance = ethers.utils.formatUnits(theBalance, theDecimals)
          } else {
            this.form.xBalance = '0'
          }
        } else {

          let theBalance = await this.provider.getBalance(this.form.address)
          this.form.xBalance = ethers.utils.formatEther(theBalance)
        }
        if (this.form.y && this.form.address) {

          let contractY = new ethers.Contract(this.form.y, tokenAbi, this.provider)
          let theBalance = await contractY.balanceOf(this.form.address);
          let theDecimals = await contractY.decimals()
          if (theBalance) {
            this.form.yBalance = ethers.utils.formatUnits(theBalance, theDecimals)
          } else {
            this.form.yBalance = '0'
          }
        } else {

          let theBalance = await this.provider.getBalance(this.form.address)
          this.form.yBalance = ethers.utils.formatEther(theBalance)
        }
      } else {
        this.form.yBalance = '0'
        this.form.xBalance = '0'
      }

    },
    async getXAndYName() {
      if (this.form.x) {
        let contractX = new ethers.Contract(this.form.x, tokenAbi, this.provider)
        let theName = await contractX.name()
        if (theName) {
          this.form.xName = theName
        } else {
          this.form.xName = 'BNB'
        }
      } else {
        this.form.xName = 'BNB'
      }
      if (this.form.y) {

        let contractY = new ethers.Contract(this.form.y, tokenAbi, this.provider)
        let theName = await contractY.name();
        if (theName) {
          this.form.yName = theName
        } else {
          this.form.yName = 'BNB'
        }
      } else {
        this.form.yName = 'BNB'
      }
    },
    async getPLC() {


      let theX = this.form.x
      let theY = this.form.y
      if (theX === '') {
        theX = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
      }
      if (theY === '') {
        theY = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
      }
      try {
        let contractY = new ethers.Contract(this.form.jiaoyisuo, factoryAbi, this.provider)
        let theLpC = await contractY.getPair(theX, theY);
        if (theLpC) {
          this.form.lpC = theLpC
        } else {
          this.form.lpC = '0x0'
        }

      } catch (e) {
        this.form.lpC = '0x0'
      }

      // if ((this.form.x && this.form.y === '') || (this.form.y && this.form.x === '')) {
      //   // let theLpC = 'await contractY.getPair(this.form.x, this.form.y)';
      //   // if (theLpC) {
      //   this.form.lpC = '0x0'
      //   // }
      //   return
      // }
      // this.form.lpC = ''
    },
    async reProvider() {
      this.provider = new ethers.providers.JsonRpcProvider(this.form.rpc);
    },
    getDeadLine() {
      let tt = new Date().getTime()
      return parseInt((tt / 1000).toString()) + 60 * 10
    },
    async getNowBili() {

      let theX = this.form.x
      let theY = this.form.y
      if (theX === '') {
        theX = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
        return
      }
      if (theY === '') {
        theY = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
        return
      }

      console.log(theX)
      console.log(theY)
      let contractX = new ethers.Contract(theX, tokenAbi, this.provider)
      let contractY = new ethers.Contract(theY, tokenAbi, this.provider)
      let xDecimals = await contractX.decimals()
      let yDecimals = await contractY.decimals()
      let xBalance = await contractX.balanceOf(this.form.lpC)
      let yBalance = await contractY.balanceOf(this.form.lpC)
      let xReBalance = ethers.utils.formatUnits(xBalance, xDecimals)
      let yReBalance = ethers.utils.formatUnits(yBalance, yDecimals)
      this.form.xNum = xReBalance
      this.form.yNum = yReBalance
      let theLpBalance = parseFloat(yReBalance) / ((parseFloat(xReBalance) + parseFloat(this.form.zhengConfig.num)) * 1.003)
      let theLpBalanceFan = (parseFloat(xReBalance) - this.form.zhengConfig.num) / (parseFloat(yReBalance) * 1.003)
      this.form.biliNow = theLpBalance;
      if (theLpBalanceFan <= 0) {
        this.form.biliNowFan = '流动性不足';
      } else {
        this.form.biliNowFan = theLpBalanceFan;
      }

    },

    async startOne() {
      // let provider = new ethers.providers.JsonRpcProvider(this.form.rpc);
      let theWallet = new ethers.Wallet(this.form.pr, this.provider)
      let contractLP = new ethers.Contract('0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D', lpAbi, this.provider)
      let contractWithSigner = contractLP.connect(theWallet)
      let contractX = new ethers.Contract(this.form.x, tokenAbi, this.provider)
      let contractY = new ethers.Contract(this.form.y, tokenAbi, this.provider)
      let xDecimals = await contractX.decimals()
      let yDecimals = await contractY.decimals()
      let xAmountIn = ethers.utils.parseUnits((parseFloat(this.form.zhengConfig.num).toFixed(xDecimals)).toString(), xDecimals)
      let yAmountOut = ethers.utils.parseUnits((this.form.zhengConfig.num * this.form.zhengConfig.bili * (100 - this.form.config.huadian) / 100).toFixed(yDecimals).toString(), yDecimals)
      let theDeadLine = this.getDeadLine()
      let res = await contractWithSigner.swapExactTokensForTokens(xAmountIn, yAmountOut, [this.form.x, this.form.y], this.form.address, theDeadLine, {
        gasPrice: ethers.utils.parseUnits(this.form.config.gasPrice, "gwei"),
        gasLimit: this.form.config.gasLimit,
      })
      let theR = await res.wait()
      console.log(theR)
      if (!this.form.config.oneTime) {
        if (theR.status === 1) {
          if(!this.overing){
            this.logs.push([theR.logs[2].data, false])
          }

        }

      }
      return theR.status === 1;


    },
    async checkApprove() {
      console.log('check')
      let theWallet = new ethers.Wallet(this.form.pr, this.provider)
      let contractX = new ethers.Contract(this.form.x, tokenAbi, this.provider)
      let contractY = new ethers.Contract(this.form.y, tokenAbi, this.provider)
      let theQuanY = await contractY.allowance(this.form.address, '0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D')
      console.log(theQuanY)
      console.log(theQuanY=== '0x00')
      if (theQuanY._hex === '0x00') {
        let contractWithSignerY = contractY.connect(theWallet)
        let theR = await contractWithSignerY.approve('0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D', '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff', {
          gasPrice: ethers.utils.parseUnits(this.form.config.gasPrice, "gwei"),
          gasLimit: this.form.config.gasLimit,
        })
        await theR.wait();
      }
      let theQuanX = await contractX.allowance(this.form.address, '0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D')
      if (theQuanX._hex === '0x00') {
        let contractWithSignerX = contractX.connect(theWallet)
        let theR = await contractWithSignerX.approve('0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D', '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff', {
          gasPrice: ethers.utils.parseUnits(this.form.config.gasPrice, "gwei"),
          gasLimit: this.form.config.gasLimit,
        })
        await theR.wait();
      }
    },
    async startOneFan() {
      // let provider = new ethers.providers.JsonRpcProvider(this.form.rpc);
      let theWallet = new ethers.Wallet(this.form.pr, this.provider)
      let contractLP = new ethers.Contract('0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D', lpAbi, this.provider)
      let contractWithSigner = contractLP.connect(theWallet)
      let contractX = new ethers.Contract(this.form.x, tokenAbi, this.provider)
      let contractY = new ethers.Contract(this.form.y, tokenAbi, this.provider)
      let xDecimals = await contractX.decimals()
      let yDecimals = await contractY.decimals()
      let theNow = []
      for (let i = this.logs.length-1; i >= 0; i--) {
        if (!this.logs[i][1]) {
          theNow = this.logs[i]
          this.logs[i][1] = true
        }
      }
      let yAmountIn = theNow[0]
      if (this.form.fanxiangType === '1') {
        console.log((Math.pow(10,xDecimals)*parseInt(theNow[0])))
        console.log((Math.pow(10,xDecimals)*parseInt(theNow[0]))/Math.pow(10,yDecimals))
        console.log((Math.pow(10,xDecimals)*parseInt(theNow[0])* (1 / this.form.fanConfig.bili))/Math.pow(10,yDecimals))
        console.log((Math.pow(10,xDecimals)*parseInt(theNow[0])* (1 / this.form.fanConfig.bili)* (100 - this.form.config.huadian))/Math.pow(10,yDecimals))
        console.log((Math.pow(10,xDecimals)*parseInt(theNow[0])* (1 / this.form.fanConfig.bili)* (100 - this.form.config.huadian)/ 100)/Math.pow(10,yDecimals))
        console.log((Math.pow(10,xDecimals)*parseInt(theNow[0])* (1 / this.form.fanConfig.bili)* (100 - this.form.config.huadian)/ 100)/Math.pow(10,yDecimals).toString())
        // console.log((Math.pow(10,xDecimals)*parseInt(theNow[0])* (1 / this.form.fanConfig.bili)* (100 - this.form.config.huadian)/ 100)/Math.pow(10,yDecimals).toString())



        let xOut ='0x'+ parseInt(((Math.pow(10,xDecimals)*parseInt(theNow[0])/Math.pow(10,yDecimals)) * (1 / this.form.fanConfig.bili) * (100 - this.form.config.huadian) / 100).toString()).toString(16)
        // let xAmountOut = ethers.utils.parseUnits((parseFloat(this.form.zhengConfig.num).toFixed(xDecimals)).toString(), xDecimals)
        let theDeadLine = this.getDeadLine()
        console.log('z')
        console.log(xOut)
        let res = await contractWithSigner.swapExactTokensForTokens(yAmountIn, xOut, [this.form.y, this.form.x], this.form.address, theDeadLine, {
          gasPrice: ethers.utils.parseUnits(this.form.config.gasPrice, "gwei"),
          gasLimit: this.form.config.gasLimit,
        })
        let theR = await res.wait()
        return theR.status === 1;
      }
      if (this.form.fanxiangType === '2') {
        let xOut ='0x'+ parseInt(((Math.pow(10,xDecimals)*parseInt(theNow[0])/Math.pow(10,yDecimals)) * (1 / (this.form.fanConfig.reBili*this.form.zhengConfig.bili)) * (100 - this.form.config.huadian) / 100).toString()).toString(16)
        // let xAmountOut = ethers.utils.parseUnits((parseFloat(this.form.zhengConfig.num).toFixed(xDecimals)).toString(), xDecimals)
        let theDeadLine = this.getDeadLine()
        console.log('y')
        console.log(xOut)
        let res = await contractWithSigner.swapExactTokensForTokens(yAmountIn, xOut, [this.form.y, this.form.x], this.form.address, theDeadLine, {
          gasPrice: ethers.utils.parseUnits(this.form.config.gasPrice, "gwei"),
          gasLimit: this.form.config.gasLimit,
        })
        let theR = await res.wait()

        return theR.status === 1;
      }


    },

    //
    // async startOneBNB() {
    //   // let wbnb = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
    //   // let provider = new ethers.providers.JsonRpcProvider(this.form.rpc);
    //   let theWallet = new ethers.Wallet(this.form.pr, this.provider)
    //   let contractLP = new ethers.Contract('0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D', lpAbi, this.provider)
    //   let contractWithSigner = contractLP.connect(theWallet)
    //   let contractX = new ethers.Contract(this.form.x, tokenAbi, this.provider)
    //   let theQuan = await contractX.allowance(this.form.address, '0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D')
    //   if (theQuan === 0) {
    //     let contractWithSignerX = contractX.connect(theWallet)
    //     let theR = await contractWithSignerX.approve('0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D', '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff', {
    //       gasPrice: ethers.utils.parseUnits(this.form.config.gasPrice, "gwei"),
    //       gasLimit: this.form.config.gasLimit,
    //     })
    //     await theR.wait();
    //   }
    //   let contractY = new ethers.Contract(this.form.y, tokenAbi, this.provider)
    //   let xDecimals = await contractX.decimals()
    //   let yDecimals = await contractY.decimals()
    //   // let xBalance = await contractX.balanceOf(this.form.lpC)
    //   // let yBalance = await contractY.balanceOf(this.form.lpC)
    //   let xAmountIn = ethers.utils.parseUnits((parseFloat(this.form.zhengConfig.num).toFixed(xDecimals)).toString(), xDecimals)
    //   let yAmountOut = ethers.utils.parseUnits((this.form.zhengConfig.num * this.form.zhengConfig.bili).toFixed(yDecimals).toString(), yDecimals)
    //   let theDeadLine = this.getDeadLine()
    //   let res = await contractWithSigner.swapExactTokensForTokens(xAmountIn, yAmountOut, [this.form.x, this.form.y], this.form.address, theDeadLine, {
    //     gasPrice: ethers.utils.parseUnits(this.form.config.gasPrice, "gwei"),
    //     gasLimit: this.form.config.gasLimit,
    //   })
    //   let theR = await res.wait()
    //   return theR.status === 1;
    //
    //
    // },
    // async startOneFanBNB() {
    //   // let wbnb = '0xbb4cdb9cbd36b01bd1cbaebf2de08d9173bc095c'
    //   // let provider = new ethers.providers.JsonRpcProvider(this.form.rpc);
    //   let theWallet = new ethers.Wallet(this.form.pr, this.provider)
    //   let contractLP = new ethers.Contract('0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D', lpAbi, this.provider)
    //   let contractWithSigner = contractLP.connect(theWallet)
    //   let contractX = new ethers.Contract(this.form.x, tokenAbi, this.provider)
    //   let contractY = new ethers.Contract(this.form.y, tokenAbi, this.provider)
    //   let theQuan = await contractY.allowance(this.form.address, '0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D')
    //   if (theQuan === 0) {
    //     let contractWithSignerY = contractY.connect(theWallet)
    //     let theR = await contractWithSignerY.approve('0xb68825C810E67D4e444ad5B9DeB55BA56A66e72D', '0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff', {
    //       gasPrice: ethers.utils.parseUnits(this.form.config.gasPrice, "gwei"),
    //       gasLimit: this.form.config.gasLimit,
    //     })
    //     await theR.wait();
    //   }
    //   let xDecimals = await contractX.decimals()
    //   let yDecimals = await contractY.decimals()
    //   // let xBalance = await contractX.balanceOf(this.form.lpC)
    //   // let yBalance = await contractY.balanceOf(this.form.lpC)
    //   if (this.form.fanxiangType === '1') {
    //
    //     let yAmountIn = ethers.utils.parseUnits((this.form.zhengConfig.num * this.form.fanConfig.bili).toFixed(yDecimals).toString(), yDecimals)
    //     // let yAmountIn = ethers.utils.parseUnits((parseFloat(this.form.zhengConfig.num).toFixed(xDecimals)).toString(), yDecimals)
    //     // (parseFloat(this.form.zhengConfig.num).toFixed(xDecimals)).toString()
    //     // let xAmountOut = ethers.utils.parseUnits((this.form.zhengConfig.num * this.form.zhengConfig.bili).toFixed(xDecimals).toString(), xDecimals)
    //     let xAmountOut = ethers.utils.parseUnits((parseFloat(this.form.zhengConfig.num).toFixed(xDecimals)).toString(), xDecimals)
    //     let theDeadLine = this.getDeadLine()
    //     let res = await contractWithSigner.swapExactTokensForTokens(yAmountIn, xAmountOut, [this.form.y, this.form.x], this.form.address, theDeadLine, {
    //       gasPrice: ethers.utils.parseUnits(this.form.config.gasPrice, "gwei"),
    //       gasLimit: this.form.config.gasLimit,
    //     })
    //     let theR = await res.wait()
    //     return theR.status === 1;
    //   }
    //   if (this.form.fanxiangType === '2') {
    //     let yAmountIn = ethers.utils.parseUnits((this.form.zhengConfig.num * this.form.zhengConfig.bili * this.form.form.fanConfig.reBili).toFixed(yDecimals).toString(), yDecimals)
    //     let xAmountOut = ethers.utils.parseUnits((parseFloat(this.form.zhengConfig.num).toFixed(xDecimals)).toString(), xDecimals)
    //     let theDeadLine = this.getDeadLine()
    //     let res = await contractWithSigner.swapExactTokensForTokens(yAmountIn, xAmountOut, [this.form.y, this.form.x], this.form.address, theDeadLine, {
    //       gasPrice: ethers.utils.parseUnits(this.form.config.gasPrice, "gwei"),
    //       gasLimit: this.form.config.gasLimit,
    //     })
    //     let theR = await res.wait()
    //     return theR.status === 1;
    //   }
    // }

  }
}
</script>

<style>
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
